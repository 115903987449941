import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, withPrefix } from 'gatsby'
import styled from 'styled-components'

import Page, { Section } from '../../shared/page-commons'
import { ACTBanner } from '../../shared/banner'
import { absoluteSize } from '../../shared/common-styles'
import {
  Grid,
  Card,
  CardActionCall,
  CardTextGroup,
} from '../../shared/card-grid'
import MDBody from './_software.md'

const Ratio = styled.div`
  padding-bottom: 100%;
`

const Image = styled.img`
  ${absoluteSize};
  display: block;
  object-fit: cover;
`

const Name = styled.h3`
  font-size: 1.125rem;
  color: var(--heading);
`

const ResearchArea = styled.p`
  flex-grow: 1;
  margin-bottom: 30px;
  margin-top: 5px;
  color: var(--text);
`

function SoftwareCard(props) {
  const { software, ...restProps } = props

  return (
    <Card {...restProps}>
      <figure css="position: relative;">
        <Ratio />
        <Image src={withPrefix(software.image_src)} alt={software.title} />
      </figure>
      <CardTextGroup>
        <Name>{software.title}</Name>
        <ResearchArea>{software.headline}</ResearchArea>
        <CardActionCall>About</CardActionCall>
      </CardTextGroup>
    </Card>
  )
}

export default function SoftwarePage(props) {
  return (
    <Page bgColor="surface">
      <Helmet>
        <meta
          name="description"
          content="Open source software development plays an important part within the ACT and we provide free software to researchers."
        />
        <meta
          property="og:description"
          content="Open source software development plays an important part within the ACT and we provide free software to researchers."
        />
        <meta
          name="twitter:description"
          content="Open source software development plays an important part within the ACT and we provide free software to researchers."
        />
      </Helmet>

      <ACTBanner src="/images/core/open_source_banner.jpg">
        Open source software projects
      </ACTBanner>
      <Section textColumn>
        <MDBody />
      </Section>
      <Section bgColor="background">
        <Grid>
          {props.data.software.nodes.map(({ frontmatter, fields }) => {
            return (
              <SoftwareCard
                key={frontmatter.title}
                software={frontmatter}
                document={frontmatter.document_url}
                href={frontmatter.external_url}
                to={frontmatter.internal_url || fields.slug}
              />
            )
          })}
        </Grid>
      </Section>
    </Page>
  )
}

export const query = graphql`
  query SoftwarePage {
    software: allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/src/collections/open_source/**" }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          image_src
          title
          headline
          document_url
          external_url
          internal_url
        }
        fields {
          slug
        }
      }
    }
  }
`
