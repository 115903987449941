import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Open source software projects",
  "banner": "/images/core/open_source_banner.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Open_science"
      }}>{`open science`}</a>{` philosophy is in the DNA of the Advanced Concepts Team and of its researchers. We believe that science is made at an enormously greater pace when open science methodologies are embraced and widely used. Open source software development is an important part of this. Together with the introduction of the `}<a parentName="p" {...{
        "href": "https://github.com/esa"
      }}>{`github`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://gitlab.com/EuropeanSpaceAgency"
      }}>{`gitlab`}</a>{` dedicated channels for the European Space Agency and the of specific programmes such as `}<a parentName="p" {...{
        "href": "https://socis.esa.int/"
      }}>{`SOCIS`}</a>{` (the ESA summer of code in space), the ACT, over the years, developed and is now maintaining a number of open source projects that are put at the service of a wider community.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      